import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["projectOptions", "presamplingProjectField"];
  declare projectOptionsTarget: HTMLElement;
  declare presamplingProjectFieldTarget: HTMLElement;
  declare hasPresamplingProjectFieldTarget: boolean;

  selectStockQuantification() {
    this.showProjectOptions();
    this.showPresamplingProjectField();
  }

  selectResearch() {
    this.hideProjectOptions();
  }

  selectPresampling() {
    this.showProjectOptions();
    this.hidePresamplingProjectField();
  }

  selectPointDataOnly() {
    this.showProjectOptions();
    this.hidePresamplingProjectField();
  }

  showProjectOptions() {
    this.projectOptionsTarget.classList.remove("hidden");
  }

  hideProjectOptions() {
    this.projectOptionsTarget.classList.add("hidden");
    this.clearPresamplingProject();
  }

  showPresamplingProjectField() {
    if (this.hasPresamplingProjectFieldTarget) {
      this.presamplingProjectFieldTarget.classList.remove("hidden");
    }
  }

  hidePresamplingProjectField() {
    if (this.hasPresamplingProjectFieldTarget) {
      this.presamplingProjectFieldTarget.classList.add("hidden");
      this.clearPresamplingProject();
    }
  }

  clearPresamplingProject() {
    if (this.hasPresamplingProjectFieldTarget) {
      const select = this.presamplingProjectFieldTarget.querySelector("select");
      if (select) {
        select.value = "";
      }
    }
  }
}
