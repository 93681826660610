import { Controller } from "@hotwired/stimulus";

type SampleType = {
  depth: number;
  default_slicing_pattern: string | null;
};

type SlicingPattern = {
  depth: number;
};

export default class extends Controller {
  static targets = ["sampleTypeSelect", "slicingPatternSelect"];
  declare sampleTypeSelectTarget: HTMLSelectElement;
  declare slicingPatternSelectTarget: HTMLSelectElement;

  static values = {
    sampleTypes: Object,
    slicingPatterns: Object,
  };
  declare sampleTypesValue: { [key: string]: SampleType };
  declare slicingPatternsValue: { [key: string]: SlicingPattern };

  connect() {
    this.selectSlicingPattern();
  }

  selectSlicingPattern() {
    const { value } = this.sampleTypeSelectTarget;
    let sampleType: SampleType | null = null;

    if (value) {
      sampleType = this.sampleTypesValue[value];
    }

    this.selectDefaultSlicingPattern(sampleType);
    this.disableInvalidSlicingPatterns(sampleType);
  }

  selectDefaultSlicingPattern(sampleType: SampleType | null) {
    if (sampleType?.default_slicing_pattern == null) {
      this.deselectSlicingPattern();
    } else {
      this.slicingPatternSelectTarget.value =
        sampleType.default_slicing_pattern;
    }
  }

  deselectSlicingPattern() {
    this.slicingPatternSelectTarget.selectedIndex = 0;
  }

  disableInvalidSlicingPatterns(sampleType: SampleType | null) {
    const validOptions = new Set(this.validSlicingPatterns(sampleType));

    Array.from(this.slicingPatternSelectTarget.options).forEach((option) => {
      option.disabled = !validOptions.has(option.value);
    });
  }

  validSlicingPatterns(sampleType: SampleType | null): string[] {
    if (sampleType == null) {
      return [""];
    }

    const validOptions: string[] = [];

    if (sampleType.default_slicing_pattern == null) {
      validOptions.push("");
    }

    Object.entries(this.slicingPatternsValue).forEach(
      ([key, slicingPattern]) => {
        if (slicingPattern.depth === sampleType.depth) {
          validOptions.push(key);
        }
      }
    );

    return validOptions;
  }
}
